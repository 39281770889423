import { useCallback } from 'react';
import cn from 'classnames';
import { HubNavBarItem, HubSector, UploadedImage } from '../../../../types';
import { HubNavBarItemTypes } from '../../../../constants';
import { concatPath } from '../../../../helpers';
import { useHubContext } from '../context/hubContext';
import styles from './Nav.module.scss';
import { Link } from './Link';
import { HomeLink } from './HomeLink';
import { ButtonLink } from './ButtonLink';
import { DropdownGroupLink } from './DropdownGroupLink';
import { SearchField } from '../../../headers/ClassicHeader/SearchField';
import ImageTag from '../../../shared/ImageTag/ImageTag';
import UserPreferences from '../UserPreferences';
import { Anchor } from '../../../shared/Anchor';

export interface NavProps {
  links: HubNavBarItem[];
  sectors: HubSector[];
  brandLogo?: UploadedImage;
  displayHomeLink?: boolean;
  displayBrandLogo?: boolean;
  displayKeywordSearch?: boolean;
  searchPlaceholderText?: string;
  displayUser?: boolean;
  fullScreen?: boolean;
}

export default function Nav(props: NavProps) {
  const {
    links,
    sectors,
    brandLogo,
    displayHomeLink,
    displayBrandLogo,
    displayKeywordSearch,
    searchPlaceholderText,
    displayUser,
    fullScreen,
  } = props;
  const { indexPath } = useHubContext();

  const containerClassName = cn({
    'container-fluid': fullScreen,
    container: !fullScreen,
  });

  const getLinkComponentByType = useCallback(
    (link: HubNavBarItem) => {
      if (link.id === 'home') {
        return <HomeLink link={link} />;
      }

      if (link.id === 'browse') {
        const sectorLinks = sectors.map((sector) => ({
          '@type': HubNavBarItemTypes.CustomLink,
          id: sector.id,
          href: `/${sector.brandUrlSuffix}/${sector.path}/`,
          label: sector.name,
        }));

        return (
          sectorLinks.length > 0 && (
            <DropdownGroupLink
              fullScreen={fullScreen}
              link={{ ...link, groupItems: sectorLinks as HubNavBarItem[] }}
            />
          )
        );
      }

      if (link['@type'] === HubNavBarItemTypes.DropdownGroup) {
        return <DropdownGroupLink fullScreen={fullScreen} link={link} />;
      }

      if (link.displayAsCTA) {
        return (
          <div className={styles.buttonLinkWrapper}>
            <ButtonLink link={link} />
          </div>
        );
      }

      return <Link link={link} />;
    },
    [sectors, fullScreen],
  );

  const searchHandler = useCallback(
    (searchValue: string) => {
      const value = encodeURIComponent(searchValue);
      const url = concatPath([indexPath, '/search/']);
      document.location.assign(`${url}?searchInput=${value}`);
    },
    [indexPath],
  );

  const logoDisplayed = displayBrandLogo && brandLogo;

  return (
    <div id="hub-nav" className={styles.wrapper}>
      <div className={containerClassName}>
        <div className="row">
          <div className="col-xs-12">
            <div className={cn(styles.body, styles.reversed)}>
              {logoDisplayed && (
                <div className={styles.brandLogoWrapper}>
                  <Anchor
                    to="/"
                    pathPrefix={indexPath}
                    className={styles.brandLogoAnchor}
                  >
                    <ImageTag
                      src={brandLogo?.path}
                      alt="Brand Logo"
                      className={styles.brandLogo}
                      lazy={false}
                    />
                  </Anchor>
                </div>
              )}
              <div
                className={cn(styles.menu, {
                  [styles.menuRight]: logoDisplayed,
                })}
              >
                <ul className={styles.list}>
                  {links.slice(displayHomeLink ? 0 : 1).map((link) => {
                    const linkComponent = getLinkComponentByType(link);
                    return (
                      linkComponent && (
                        <li key={link.id} className={styles.listItem}>
                          {linkComponent}
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
              {displayKeywordSearch && (
                <div className={styles.searchWrapper}>
                  <div className={styles.searchField}>
                    <SearchField
                      onSearch={searchHandler}
                      placeholder={searchPlaceholderText}
                    />
                  </div>
                  <div className={styles.searchButton}>
                    <SearchField
                      variant="button"
                      onSearch={searchHandler}
                      placeholder={searchPlaceholderText}
                    />
                  </div>
                </div>
              )}
              {displayUser && (
                <div className={styles.user}>
                  <UserPreferences />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Nav.defaultProps = {
  sectors: [],
};
